<div class="container loginbody">
    <div class="login-box">
        <div class="login-logo">
            <img class="login-logo-image" src="assets/images/netconnectlogoblue.png" alt="NetConnect">
        </div>
        <div class="login-logo">
            <span i18n="@@WelcomeBackExclamationMark">NetConnect</span>
        </div>
        <loginform></loginform>
        
    </div>
    <div class="image-container">
        <img src="/Admin/uploads/logo.png" onerror="this.onerror=null; this.remove();">
    </div>
</div>
import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: []

})
export class LoginComponent {
  currentLocale: string;

  constructor(private msalService: MsalService) {

  }


  ngOnInit() {
    this.currentLocale = localStorage.getItem('currentLocale');
    if (!!localStorage.getItem('userToken')) {
      localStorage.setItem('userToken', null);
    }

    const accounts = this.msalService.instance.getAllAccounts();

    if (accounts.length <= 0) {
      const itemKey = 'msal.interaction.status';
      if (sessionStorage.getItem(itemKey)) {
        sessionStorage.removeItem(itemKey);
      }
    }

  }
}